<script setup>
import { ref, computed } from 'vue';
import { getDataObjectById } from 'o365-dataobject';
import { procSJAWorkflowAction } from 'sja.vue.libraries.modules.utilities.js'

const props = defineProps({
    actionRow: Object,
    sjaID: Number,
    readOnly: Boolean
});

let dsResponsible = getDataObjectById(`dsTasksHazardsActionsResp_${props.sjaID}`);
let dsSJA = getDataObjectById(`dsSJA_${props.sjaID}`)
let isHidden = ref(false);

const responsibleData = computed(() => {
    return dsResponsible.data.filter(item => item.SJATasksHazardsAction_ID == props.actionRow.ID);
});

async function createWorkflowAction(responsible, actionRow, stepID) {
    isHidden.value = true;
    try {
        await procSJAWorkflowAction.execute({ Step_ID: stepID, Responsible_ID: responsible.Responsible_ID, ResponsibleRow_ID: responsible.ID, Action_Title: actionRow.Name, Action_Comment: actionRow.Comment });
    }
    finally {
        await dsResponsible.load();
        isHidden.value = false;
    }
}

</script>

<template>
    <span v-for="(responsible, index) in responsibleData" class="border mt-1 d-flex flex-row flex-wrap p-1 me-2">
        <i v-if="responsible.StepAction_ID" class="me-1 bi bi-circle-fill"
            :class="{ 'text-danger': !responsible.Completed, 'text-success': responsible.Completed }"></i>
        <span class="me-auto text-truncate">
            {{ responsible.Name }}
        </span>
        <div class="d-flex align-items-center">
            <i v-if="!props.readOnly && dsSJA.current.Step_ID && !responsible.StepAction_ID" type="button"
                :title="$t('Create workflow action.')" :hidden="isHidden" class="ps-2 text-black bi bi-plus-circle"
                @click="createWorkflowAction(responsible, props.actionRow, dsSJA.current.Step_ID)"></i>
            <OActionDelete confirm v-if="!props.readOnly" :dataObject="dsResponsible" :row="responsible"
                class="btn btn-sm btn-link p-0" :title="$t('Remove person.')" style="-bs-btn-line-height: 1;">
                <i class="ps-2 text-black bi bi-x-lg"></i>
            </OActionDelete>
        </div>
    </span>
</template>